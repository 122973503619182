import { microRequest } from '@/utils/request'
import consts from '@/constants'

export function searchByMulti (params) {
    return microRequest({
        url: consts.url.SEARCH_BY_MULTI,
        method: 'post',
        data: params
    })
}
