import CmyPage from '@/components/cmy-page/cmy-page.vue'
import { doQuery, doAction } from "@/api/core"
import { searchByMulti } from "@/api/lesson";
import {mapActions} from "vuex";

export default {
    name: "taxGuide",
    data() {
        return {
            tabs: [
                {
                    icon: 'icon-qiye',
                    name: '企业',
                    key: 'company'
                }/*,{
                    icon: 'icon-getihu',
                    name: '个体户及个人',
                    key: 'person'
                }*/
            ],
            activity_key: 'company',
            search_text: '',

            tax_menu_list2: [],
            tax_menu_list: [],

            tax_menu_select: [],
            tax_guide_list: [],
            tax_guide_pagination: {
                page: 1,
                limit: 20,
                end: false
            },


            searchCount: 0,
            //排序条件
            sorttypeArray: ["按相关程度排序", "按发布时间排序"],
            sorttypeField: ['', 'create_time'],
            sorttypeIndex: 0,
            browseMode: '0',
        }
    },
    components: {
        CmyPage,
    },
    mounted() {
      const that = this
        that.getTaxMenu()
    },
    methods: {
        ...mapActions([
            'RefreshPkdbtypes',
            'RefreshKdbtypes',
            'GetKdbtypesByPkdbtypeCode',
            'GetKdbtypeCodesByPkdbtypeCode',
            'GetPkdbtypeByKdbtypeCode',
            'GetTxttypeByKdbtypeCode'
        ]),
        onSortTypeMenuClick({ key }) {
            const that = this
            that.sorttypeIndex = key
            that.doSearchByMulti()
        },

        onSearch() {
            const that = this
            var keywords = that.$cmyUtils.trim(that.search_text)
            if(!keywords) {
                that.browseMode = '0'

                that.queryTaxGuide()
                return
            }
            that.doSearchByMulti()
        },
        handleTaxMenuClick({ item, key, keyPath }) {
            const that = this
            that.tax_menu_select = [key]
            if(that.browseMode == '9') {
                that.doSearchByMulti()
            }else if(that.browseMode == '0') {
                that.queryTaxGuide()
            }
        },
        doSearchByMulti(page = 1) {
            const that = this
            var kdbtypeCodeArr = []
            if(that.tax_menu_select[0] == '%') {
                kdbtypeCodeArr = that.tax_menu_list2.map(item => item.kdbtype_code)
            }else {
                kdbtypeCodeArr = that.tax_menu_select
            }
            searchByMulti({
                keywords: that.$cmyUtils.clearTxt(that.search_text),
                matchType: '2',
                basis: '',
                endTime: '',
                isInvalid: '',
                kdbtypeCodeArr: kdbtypeCodeArr,
                refSrc: '',
                startTime: '',
                sortField:that.sorttypeField[that.sorttypeIndex],
                page: page,
                limit: that.tax_guide_pagination.limit
            }).then(res => {
                if(res.errcode != '0' || !res.data) {
                    that.$message.error('搜索发生异常，请稍后再试')
                    that.tax_guide_list = []
                    that.tax_guide_pagination.end = true
                    return
                }
                that.tax_guide_pagination.page = page

                var doc = res.data.searchList //如果没有搜索到结果
                that.searchCount = res.data.totalCount
                that.browseMode = '9'

                if(doc.length < that.tax_guide_pagination.limit) {
                    that.tax_guide_pagination.end = true
                }else {
                    that.tax_guide_pagination.end = false
                }

                if (!doc || doc.length == 0) {
                    that.tax_guide_list = []
                }else {
                    doc.forEach(function(item, index) {
                        that.setSearchItem(item)
                    })
                    that.tax_guide_list = doc
                }
            })
        },
        /**
         * 对搜索结果的item进行特别处理
         */
        setSearchItem: function(item) {
            var that = this
            //结果加红标记处理
            if (item.title) {
                item.title = item.title.replace(/<font color='red'>/g, '<a style="color:red;">')
                item.title = item.title.replace(/<\/font>/g, '</a>')
            }
            if (item.summary) {
                item.summary = item.summary.replace(/<font color='red'>/g, '<a style="color:red;">')
                item.summary = item.summary.replace(/<\/font>/g, '</a>')
                item.summary = '...' + item.summary + '...'
            }
            if (item.refSrc) {
                item.refSrc = item.refSrc.replace(/<font color='red'>/g, '<a style="color:red;">')
                item.refSrc = item.refSrc.replace(/<\/font>/g, '</a>')
            }
            if (item.basis) {
                item.basis = item.basis.replace(/<font color='red'>/g, '<a style="color:red;">')
                item.basis = item.basis.replace(/<\/font>/g, '</a>')
            }
            //增加父类型、有效状态的标记在最前面
            Promise.all([
                that.GetPkdbtypeByKdbtypeCode(item.kdbtypeCode)
                    .then(res => {
                        item.pkdbtype = res
                    }),
                that.GetTxttypeByKdbtypeCode(item.kdbtypeCode)
                    .then(res => {
                        item.txttype = res
                    })
            ]).then(() => {
                //如果不是法规，表示参考法规
                if (item.txttype && item.txttype != '' && item.txttype.indexOf('法规') < 0) {
                    if (item.basis && item.basis != '') {
                        item.basis = '【参考法规】' + item.basis
                    }
                }
                if (item.isInvalid == '0') {
                    item.title =
                        '<a style="background-color:#e93d52;color:white;font-size:12px;height:17px;line-height:17px;padding: 0 2px;margin-right:2px;border-radius:2px;">全文失效</a>' +
                        item.title
                }
                if (item.isInvalid == '2') {
                    item.title =
                        '<a style="background-color:#f56400;color:white;font-size:12px;height:17px;line-height:17px;padding: 0 2px;margin-right:2px;border-radius:2px;">条款失效</a>' +
                        item.title
                }
            })

        },
        queryTaxGuide(page = 1) {
            const that = this
            doQuery({
                query_code: 'LNT400',
                param_str1: that.tax_menu_select[0],
                page: page,
                limit: that.tax_guide_pagination.limit
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data) {
                    that.tax_guide_list = res.data
                    that.tax_guide_pagination.page = page
                    that.tax_guide_pagination.end = res.data.length < that.tax_guide_pagination.limit
                }
            })
        },
        getTaxMenu() {
            const that = this
            doQuery({
                query_code: 'LNT300',
                page:1,
                limit:50
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data) {
                    that.tax_menu_list2 = res.data
                    that.tax_menu_list = [{kdbtype: '全部类型',kdbtype_code: '%'},...res.data]
                    that.tax_menu_select = [that.tax_menu_list[0].kdbtype_code]
                    that.queryTaxGuide()
                }
            })
        },
        toArticle(id) {
            const that = this
            var routeData = that.$router.resolve({name: 'Article', query: {id: id}})
            window.open(routeData.href, '_blank')
        }
    }
}